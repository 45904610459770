/* ======================================================================== */
/* 27. parallax */
/* ======================================================================== */
.art-parallax, [data-art-parallax]
	position: relative
	overflow: hidden
	> img
		width: 100%
		height: 100%
		object-fit: cover
.art-parallax__bg
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-size: cover
	background-position: center center
