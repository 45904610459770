/* ======================================================================== */
/* 24. overlay */
/* ======================================================================== */
.overlay
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	opacity: 0
	z-index: -1
.overlay_dark
	background-color: $dark
	opacity: .8
	mix-blend-mode: multiply
.overlay_black
	background-color: $black
	opacity: .8
.overlay_white
	background-color: $white
	opacity: .95
.overlay_white-solid
	background-color: $white
	opacity: 1

