/* ======================================================================== */
/* 2. asideCounters */
/* ======================================================================== */
.aside-counters__wrapper-item
	position: relative
	margin-bottom: 60px
	margin-top: 60px
	&:after
		content: ''
		display: block
		position: absolute
		top: 10px
		bottom: 10px
		right: 0
		width: 1px
		background-color: $borders-dark
		transform: rotate(30deg)
	&:nth-of-type(3n)
		&:after
			display: none

@media only screen and (max-width: $sm)
	.aside-counters__wrapper-item
		&:after
			transform: none
			right: 15px
			left: 15px
			top: auto
			bottom: -40px
			width: auto
			// width: 100%
			height: 1px
	.aside-counters__wrapper-item
		margin-bottom: 40px
		margin-top: 40px
