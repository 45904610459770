/* * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 *
 * Rubenz – Creative Portfolio AJAX Template
 *
 * [Table of Contents]
 *
 * 1. asideCounters
 * 2. backgrounds
 * 3. button
 * 4. comments
 * 5. counter
 * 6. cursor
 * 7. figureImage
 * 8. figureLogo
 * 9. figureMember
 * 10. figurePortfolio
 * 11. figurePricing
 * 12. figureProperty
 * 13. footer
 * 14. form
 * 15. gmap
 * 16. grid
 * 17. header
 * 18. inputFloat
 * 19. inputSearch
 * 20. lazy
 * 21. logo
 * 22. menu
 * 23. menuOverlay
 * 24. overlay
 * 25. parallax
 * 26. post
 * 27. postPreview
 * 28. preloader
 * 29. projectBackgrounds
 * 30. scroll
 * 31. section
 * 32. section404
 * 33. sectionBlog
 * 34. sectionComposition
 * 35. sectionContent
 * 36. sectionCTA
 * 37. sectionFigures
 * 38. sectionFullscreenSlider
 * 39. sectionImage
 * 40. sectionIntro
 * 41. sectionMap
 * 42. sectionMasthead
 * 43. sectionNavProjects
 * 44. sectionVideo
 * 45. select
 * 46. sidebar
 * 47. slider
 * 48. sliderFullscreen
 * 49. sliderHalfScreen
 * 50. sliderHeadings
 * 51. sliderTestimonials
 * 52. sliderText
 * 53. social
 * 54. spinner
 * 55. splitText
 * 56. tags
 * 57. typography
 * 58. utilities
 * 59. widget
 * 60. widgetArchive
 * 61. widgetCalendar
 * 62. widgetCategories
 * 63. widgetLogo
 * 64. widgetNavMenu
 * 65. widgetRecentComments
 * 66. widgetRecentEntries
 * 67. widgetRSS

 * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 */

@function rem($size) {
	$remSize: $size / $em-base;
	@return #{$remSize}rem; }
@mixin trans1 {
	transition: all 0.4s cubic-bezier(0.785, 0.135, 0.150, 0.860); }
@mixin trans2 {
	transition: all 0.6s cubic-bezier(0.785, 0.135, 0.150, 0.860); }
@mixin reset-ul {
	list-style-type: none;
	padding: 0;
	margin: 0; }

@mixin small-caps {
	font-family: $font-secondary;
	font-size: 13px;
	line-height: 1.3;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	@media only screen and (max-width: 991px) {
		font-size: 11px;
		letter-spacing: 0.8px; } }

@mixin link-underline {
	&:after, &:before {
		content: '';
		position: absolute;
		bottom: 0;
		width: 0;
		right: 0;
		height: 1px;
		background-color: $white; }
	&:before {
		transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s; }
	&:after {
		transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83); }
	&:after, &:before {
		width: calc(100% - 10px);
		left: 0; } }

@mixin material-icons {
	font-family: 'Material Icons';
	font-style: normal;
	letter-spacing: normal;
	text-transform: none;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	font-feature-settings: 'liga';
	ms-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility; }

$em-base: 16px;
$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Oswald', sans-serif;

// Color Palette
$black: #333333;
$dark: #181818;
$dark-1: #1f1f1f;
$dark-2: #232323;
$black0: #0f0f0f;
$black1: #121212;
$white: #ffffff;
$light-grey: #f9f9f9;
$very-light-pink: #f2f2f2;
$light-blue-grey: #d6d7d9;
$light-blue-grey-2: #c5c6c9;
$brown-grey: #b0b0b0;
$brown-grey-2: #888888;
$borders-light: rgba(214, 215, 217, .2);
$borders-dark: rgba(176, 176, 176, .3);

// Responsive Breakpoints
$xxl: 1400px;
$xl: 1200px;
$lg: 1024px;
$md: 991px;
$sm: 767px;
$xs: 576px;

html {
	font-size: $em-base;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	overflow-x: hidden;
	width: 100%;
	height: 100%; }
body {
	position: relative;
	width: 100%;
	min-width: 320px;
	font-family: $font-primary;
	background-color: $light-blue-grey-2;
	color: $black;
	font-size: 100%;
	line-height: 1.75;
	overflow-x: hidden;
	word-wrap: break-word; }
.body_lock-scroll {
	overflow-y: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0; }
.body_custom-cursor {
	cursor: none; }
img {
	max-width: 100%;
	height: auto; }
.container-fluid {
	padding-left: 120px;
	padding-right: 120px; }
.container {
	padding-left: 25px;
	padding-right: 25px; }
.row {
	margin-left: -25px;
	margin-right: -25px; }
.col, [class*='col-'] {
	padding-left: 25px;
	padding-right: 25px; }
.no-gutters {
	margin-left: 0 !important;
	margin-right: 0 !important; }
.page-wrapper {
	z-index: 50; }

@media screen and (max-width: $xxl) {
	.container-fluid {
		padding-left: 80px;
		padding-right: 80px; } }
@media screen and (max-width: $xl) {
	.container-fluid {
		padding-left: 50px;
		padding-right: 50px; } }
@media screen and (max-width: $md) {
	.container-fluid, .container {
		padding-left: 30px;
		padding-right: 30px; }
	.row {
		margin-left: -15px;
		margin-right: -15px; }
	.col, [class*='col-'] {
		padding-left: 15px;
		padding-right: 15px; } }

/* ======================================================================== */
/* 1. backgrounds */
/* ======================================================================== */
.bg-white {
	background-color: $white; }
.bg-light {
	background-color: $very-light-pink;
	.slider-header-testimonials {
		&:before {
			background: linear-gradient(-90deg, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 100%); }
		&:after {
			background: linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 100%); } } }
.bg-light-grey {
	background-color: $light-grey; }
.bg-blue-grey {
	background-color: $light-blue-grey; }
.bg-blue-grey-dark {
	background-color: $light-blue-grey-2;
	.aside-counters__wrapper-item {
		&:after {
			background-color: $brown-grey; } } }
.bg-dark {
	background-color: $dark; }
.bg-dark-2 {
	background-color: $dark-2; }
.bg-black {
	background-color: $black; }
.bg-black-0 {
	background-color: $black0; }
.bg-black-1 {
	background-color: $black1; }
.color-white {
	.slider__caption {
		color: $white; }
	.slider__counter_current {
		color: $white; }
	.slider__progressbar {
		background-color: $brown-grey-2; }
	.slider__progressbar-fill {
		background-color: $white; }
	.figure-property__headline {
		background-color: $white; }
	.section-intro__headline {
		background-color: $white; }
	.section__headline {
		background-color: $white; } }

@for $i from 1 through 9 {
	.bg-image-#{$i} {
		background-image: url('../img/assets/projects/bg-#{$i}.jpg'); } }
div[class*='bg-image-'] {
	background-size: cover;
	background-position: center center; }

/* ======================================================================== */
/* 2. asideCounters */
/* ======================================================================== */
.aside-counters__wrapper-item {
	position: relative;
	margin-bottom: 60px;
	margin-top: 60px;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 10px;
		bottom: 10px;
		right: 0;
		width: 1px;
		background-color: $borders-dark;
		transform: rotate(30deg); }
	&:nth-of-type(3n) {
		&:after {
			display: none; } } }

@media only screen and (max-width: $sm) {
	.aside-counters__wrapper-item {
		&:after {
			transform: none;
			right: 15px;
			left: 15px;
			top: auto;
			bottom: -40px;
			width: auto;
			// width: 100%
			height: 1px; } }
	.aside-counters__wrapper-item {
		margin-bottom: 40px;
		margin-top: 40px; } }

/* ======================================================================== */
/* 3. button */
/* ======================================================================== */
.button {
	position: relative;
	display: inline-block;
	padding: 22px 50px;
	border-radius: 100px;
	border-style: solid;
	border-width: 2px;
	border-color: transparent;
	@include small-caps;
	text-align: center;
	@include trans1;
	cursor: pointer;
	background-image: none;
	&:focus {
		outline: none; }
	&:hover, &:focus {
		opacity: 1;
		transform: translateY(-3px); } }
.button_bordered {
	&.button_dark {
		border-color: $dark;
		color: $dark;
		&:focus {
			color: $dark; }
		&:hover {
			color: $dark; } }
	&.button_light {
		border-color: $light-grey;
		color: $light-grey;
		&:focus {
			color: $light-grey; }
		&:hover {
			color: $dark; } }
	&.button_black {
		border-color: $black;
		color: $black;
		&:focus {
			color: $black; }
		&:hover {
			color: $white; } }
	&.button_white {
		border-color: $white;
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			color: $dark;
			background-color: $white; } } }
.button_solid {
	border-color: transparent;
	&.button_dark {
		background-color: $dark;
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: $dark; } }
	&.button_light {
		background-color: $light-grey;
		color: $dark;
		&:focus {
			color: $dark; }
		&:hover {
			border-color: $light-grey; } }
	&.button_black {
		background-color: $black;
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: $dark; } }
	&.button_white {
		background-color: $white;
		color: $dark;
		&:focus {
			color: $dark; }
		&:hover {
			border-color: $dark;
			background-color: $dark;
			color: $white; } } }
.button_fullwidth {
	width: 100%; }
.button_small {
	padding: 16px 32px; }

/* ======================================================================== */
/* 4. comments */
/* ======================================================================== */
.comment-list {
	@include reset-ul;
	> li {
		padding-top: 30px;
		padding-bottom: 30px;
		ol.children {
			@include reset-ul;
			padding-left: 4%;
			li {
				padding-top: 30px;
				padding-bottom: 30px; }
			li:last-child {
				padding-bottom: 0; } } }
	> li:not(:last-child) {
		border-bottom: 1px solid $borders-dark; }
	> ol {
		@include reset-ul; } }
.comment-author {
	display: inline-block;
	.avatar {
		position: relative;
		width: 100%;
		max-width: 100px;
		max-height: 100px;
		border-radius: 100%;
		margin-right: 1.5em;
		@media only screen and (max-width: $md) {
			width: 50px;
			height: 50px; } } }

.comment-meta {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.5em;
	a {
		background-image: none; }
	@media only screen and (max-width: $md) {
		flex-wrap: wrap; } }

.comment-metadata {
	@include small-caps;
	margin-left: 10px;
	a:not(.comment-edit-link) {
		color: $brown-grey;
		&:hover {
			color: var(--color-accent-secondary); } }
	a {
		border-bottom: none !important; }
	@media only screen and (max-width: $md) {
		margin-top: 5px;
		margin-left: 0; } }
.comment-body {
	display: flex;
	.fn {
		font-size: 20px;
		font-weight: 600;
		line-height: 1.44;
		color: $dark;
		a {
			border-bottom: none; } } }
.comment-author {
	flex: 0 0 auto; }
.comment-content {
	width: 100%; }
.reply {
	display: inline-block; }
.comment-reply-link {
	@include small-caps;
	border-bottom: none !important;
	margin-right: 24px;
	background-image: none;
	&:before {
		content: '\f112';
		font-family: 'FontAwesome';
		text-transform: none;
		margin-right: 8px; } }
.comment-respond {
	margin-top: 10px; }
.comment-edit-link {
	@include small-caps;
	color: var(--color-accent-primary);
	border-bottom: none !important;
	background-image: none;
	&:before {
		content: '\f044';
		font-family: 'FontAwesome';
		text-transform: none;
		margin-right: 6px; } }

/* ======================================================================== */
/* 5. figureImage */
/* ======================================================================== */
.figure-image {
	text-align: center;
	img {
		width: 100%; }
	h5 {
		display: block;
		width: 100%;
		margin-top: 1em; } }

/* ======================================================================== */
/* 6. counter */
/* ======================================================================== */
.counter {
	text-align: center; }
.counter__number {
	font-size: 68px;
	font-weight: 200;
	line-height: 1; }
.counter__label {
	margin-top: 5px; }

@media screen and (max-width: $xxl) {
	.counter__number {
		font-size: 54px; } }
@media screen and (max-width: $md) {
	.counter__number {
		font-size: 43px; } }

/* ======================================================================== */
/* 7. cursor */
/* ======================================================================== */
.cursor {
	position: fixed;
	user-select: none;
	pointer-events: none;
	z-index: 10000;
	display: none; }
.cursor__follower {
	position: relative;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	overflow: hidden;
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		#inner, #outter {
			fill: none;
			stroke-linecap: butt; }
		#inner {
			stroke-width: 2px;
			stroke: $brown-grey-2;
			opacity: .6; }
		#outter {
			stroke-width: 4px;
			stroke: $light-blue-grey-2; } } }
.cursor-progress {
	cursor: progress;
	* {
		cursor: progress; } }

/* ======================================================================== */
/* 8. figureLogo */
/* ======================================================================== */
.figure-logo {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 330px;
	height: 330px;
	padding: 30px;
	img {
		@include trans1; }
	&:hover {
		.figure-logo__description {
			transform: scaleY(1);
			p {
				transform: translateY(0px);
				opacity: 1;
				visibility: visible;
				transition-delay: 0.15s; }
			.figure-logo__line {
				transition-delay: 0.2s;
				transform: scaleX(1); } }
		img {
			transform: translateY(-15px); } } }
.figure-logo__description {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $dark;
	color: $white;
	@include trans1;
	transform: scaleY(0);
	transform-origin: bottom center;
	p {
		opacity: 0;
		visibility: hidden;
		transform: translateY(10px);
		@include trans1; } }
.figure-logo__line {
	display: inline-block;
	width: 30px;
	height: 1px;
	background-color: $white;
	transform: scaleX(0);
	@include trans1; }

@media screen and (max-width: $xl) {
	.figure-logo {
		width: 270px;
		height: 270px;
		max-width: 100%; } }
@media screen and (max-width: $md) {
	.figure-logo {
		width: 100%; } }

/* ======================================================================== */
/* 9. figureMember */
/* ======================================================================== */
.figure-member {
	position: relative; }
.figure-member_has-social {
	&:hover {
		.figure-member__wrapper-img img {
			transform: scale(1.1); }
		.figure-member__overlay {
			transform: scaleX(1); }
		.figure-member__social {
			li {
				transform: translateX(0px);
				opacity: 1;
				visibility: visible;
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition-delay: 50ms + $i * 50ms; } } } } } }
.figure-member__content {
	text-align: right;
	margin-top: 1em; }
.figure-member__position {
	@include small-caps;
	position: absolute;
	top: -7px;
	right: calc(100% + 25px);
	color: $brown-grey-2;
	transform: rotate(-90deg);
	transform-origin: right center;
	text-align: right;
	white-space: nowrap; }
.figure-member__wrapper-img {
	position: relative;
	overflow: hidden;
	img {
		@include trans1;
		transform-origin: center center; } }
.figure-member__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $dark;
	@include trans1;
	transform: scaleX(0);
	transform-origin: right center; }
.figure-member__social {
	position: absolute;
	top: 30px;
	left: 30px;
	z-index: 50;
	color: $white;
	li {
		display: block;
		transform: translateX(30px);
		transition: all 0.6s ease;
		opacity: 0;
		visibility: hidden;
		margin-right: 0;
		margin-bottom: 15px; } }

@media screen and (max-width: $xxl) {
	.figure-member__position {
		font-size: 12px; } }
@media screen and (max-width: $md) {
	.figure-member {
		text-align: center; }
	.figure-member__content {
		text-align: center;
		margin-top: 20px; }
	.figure-member__position {
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		transform: none;
		text-align: center;
		margin-bottom: 10px; } }

/* ======================================================================== */
/* 10. figurePortfolio */
/* ======================================================================== */
.figure-portfolio {
	position: relative;
	display: block;
	background-image: none;
	&:hover, &:focus {
		opacity: 1; } }
.figure-portfolio__content {
	text-align: right;
	h3 {
		margin-top: 30px;
		margin-bottom: 0;
		color: $black;
		font-weight: 600; } }
.figure-portfolio__counter {
	font-family: $font-secondary;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 1px;
	text-transform: uppercase;
	display: inline-block;
	position: absolute;
	top: -30px;
	left: -30px;
	color: $brown-grey-2;
	transform: rotate(-90deg);
	transform-origin: left center; }

@media screen and (max-width: $xxl) {
	.figure-portfolio__counter {
		font-size: 12px;
		top: -20px;
		left: -20px; }
	.figure-portfolio__content {
		h3 {
			margin-top: 20px; } } }
@media screen and (max-width: $md) {
	.figure-portfolio__counter {
		top: -13px;
		left: -13px; }
	.figure-portfolio__content {
		text-align: center;
		h3 {
			margin-top: 15px; } } }

/* ======================================================================== */
/* 11. figurePricing */
/* ======================================================================== */
.figure-pricing {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 50px;
	position: relative;
	height: 100%;
	z-index: 50;
	background-color: $very-light-pink; }
.figure-pricing_featured {
	background-color: $dark;
	color: $white;
	.figure-pricing__header, .button {
		border-color: $white; } }
.figure-pricing__header {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	border-bottom: 2px solid $dark;
	padding-bottom: 16px;
	h4 {
		margin-top: 0;
		margin-bottom: 0; } }
.figure-pricing__amount {
	font-size: 68px;
	line-height: 1;
	font-weight: 200;
	position: relative;
	display: inline-block; }
.figure-pricing__cost-sign {
	display: inline-block;
	font-size: 16px;
	font-weight: 600;
	vertical-align: top; }
.figure-pricing__cost-sign_before {
	margin-right: 5px; }
.figure-pricing__cost-sign_after {
	margin-left: 5px; }
.figure-pricing__features {
	@include reset-ul;
	margin-top: 8px;
	margin-bottom: 30px; }
.figure-pricing__feature {
	display: block;
	margin-top: 16px;
	margin-bottom: 16px; }
.figure-pricing__wrapper-button {
	margin-top: auto; }

@media screen and (max-width: $xl) {
	.figure-pricing {
		padding: 30px; }
	.figure-pricing__amount {
		font-size: 53px; }
	.figure-pricing__cost {
		margin-bottom: -5px; } }

/* ======================================================================== */
/* 12. figureProperty */
/* ======================================================================== */
.figure-property {
	width: 100%; }
.figure-property__headline {
	width: 100%;
	height: 2px;
	background-color: $dark;
	margin-bottom: 16px; }
.figure-property__wrapper-heading_bold {
	h6 {
		font-size: 20px;
		font-family: $font-primary;
		font-weight: 600;
		text-transform: none;
		letter-spacing: 0; } }
.figure-property__list {
	@include reset-ul; }
.figure-property__item {
	display: block;
	padding-top: 8px;
	padding-bottom: 8px; }

@media screen and (max-width: $md) {
	.figure-property__wrapper-heading_bold h6 {
		font-size: 16px; } }

/* ======================================================================== */
/* 13. footer */
/* ======================================================================== */
.footer {
	position: relative;
	background-color: $black0;
	color: $brown-grey-2;
	font-size: 16px;
	a {
		color: $white; } }
.footer__area-primary {
	padding-top: 160px;
	padding-bottom: 160px;
	.footer__col {
		&:nth-child(1) {
			text-align: left; }
		&:nth-child(2) {
			text-align: center; }
		&:nth-child(3) {
			text-align: right; } } }
.footer__area-secondary {
	padding-top: 50px;
	padding-bottom: 50px;
	border-top: 1px solid $borders-light;
	.footer__col {
		&:nth-child(1) {
			text-align: center; } } }

@media screen and (max-width: $xl) {
	.footer__area-primary {
		padding-top: 100px;
		padding-bottom: 100px; }
	.footer__area-secondary {
		padding-top: 30px;
		padding-bottom: 30px; } }

@media screen and (max-width: $md) {
	.footer__area-primary {
		padding-top: 60px;
		padding-bottom: 50px;
		.footer__col {
			margin-bottom: 30px;
			&:nth-of-type(1) {
				text-align: center; }
			&:nth-of-type(2) {
				text-align: center; }
			&:nth-of-type(3) {
				text-align: center; }
			&:last-of-type {
				margin-bottom: 0; } } }
	.footer__area-secondary {
		padding-top: 20px;
		padding-bottom: 20px; } }

/* ======================================================================== */
/* 14. form */
/* ======================================================================== */
.form {
	width: 100%; }
.form__submit {
	margin-top: 10px; }
.form__col {
	margin-top: 15px;
	margin-bottom: 15px; }
.form__col_submit {
	margin-top: 30px;
	margin-bottom: 0; }
.form__error, span.wpcf7-not-valid-tip {
	display: block;
	font-size: 12px;
	color: red;
	text-align: left;
	margin-top: 4px; }

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
	border-color: red;
	padding: 15px;
	margin: 1.5em 0 0;
	display: none !important; }

@media only screen and (max-width: 992px) {
	.form__col_submit {
		text-align: left; } }

/* ======================================================================== */
/* 15. gmap */
/* ======================================================================== */
.gmap {
	width: 100%;
	height: 100%;
	background-color: $light-blue-grey-2; }
.gmap__container {
	width: 100%;
	height: 100%;
	height: 900px; }

@media only screen and (max-width: $md) {
	.gmap__container {
		height: 600px;
		max-height: 120vh; } }

/* ======================================================================== */
/* 16. header */
/* ======================================================================== */
.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 500;
	padding-top: 70px; }
.header_white {
	.logo {
		color: $white;
		&:focus {
			color: $white; } }
	.header__burger-line {
		background-color: $white; }
	.menu {
		> li a {
			color: $white; }
		> li:not(.menu-item-has-children) > a {
			&:before {
				background-color: $white; } }
		.sub-menu {
			background-color: $dark; } } }
.header_fixed {
	position: fixed; }
.header__burger {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	z-index: 600;
	&:not(.header__burger_opened):hover {
		.header__burger-line {
			&:nth-child(1) {
				transform: scaleX(0.75); }
			&:nth-child(2) {
				transform: scaleX(1.25); } } } }
.header__burger_opened {
	.header__burger-line {
		background-color: $white;
		&:nth-child(1) {
			transform: scaleX(0.75); }
		&:nth-child(2) {
			transform: scaleX(1.25); } }
	&:hover {
		.header__burger-line {
			&:nth-child(1) {
				transform: scaleX(1); }
			&:nth-child(2) {
				transform: scaleX(1); } } } }
.header__burger-line {
	display: block;
	width: 100%;
	background-color: $black;
	height: 2px;
	margin: 4px 0;
	@include trans2;
	transform-origin: right center;
	&:nth-child(1) {
		max-width: 40px; }
	&:nth-child(2) {
		max-width: 30px; } }
.header__wrapper-overlay-menu {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 500;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	opacity: 0;
	visibility: hidden; }
.header__wrapper-menu {
	display: flex;
	align-items: flex-end;
	padding: 120px 120px 40px;
	width: 100%; }
.header__wrapper-overlay-widgets {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex: 1 1 auto;
	padding: 120px 120px 60px; }
.header__wrapper-property {
	margin-bottom: 50px;
	font-size: 16px;
	&:last-of-type {
		margin-bottom: 0; } }
.header__overlay-menu-back {
	position: absolute;
	top: 70px;
	left: 110px;
	font-size: 45px !important;
	color: $white;
	z-index: 600;
	cursor: pointer; }
.header__col-left {
	position: relative; }
.header_lock-submenus {
	.menu {
		.sub-menu {
			opacity: 0 !important;
			visibility: hidden !important;
			transform: translate(0, 10px) !important; } } }

@media screen and (max-width: $xxl) {
	.header {
		padding-top: 50px; }
	.header__wrapper-menu {
		padding: 80px 80px 40px; }
	.header__wrapper-overlay-widgets {
		padding: 80px 80px 60px; }
	.header__overlay-menu-back {
		top: 50px;
		left: 70px; } }

@media screen and (max-width: $xl) {
	.header {
		padding-top: 40px; }
	.header__wrapper-menu {
		padding: 50px 50px 30px; }
	.header__wrapper-overlay-widgets {
		padding: 50px 50px 40px; }
	.header__wrapper-property {
		margin-bottom: 40px; }
	.header__overlay-menu-back {
		top: 40px;
		left: 40px; } }

@media screen and (max-width: $md) {
	.header__burger-line {
		margin: 3px 0;
		&:nth-child(1) {
			max-width: 30px; }
		&:nth-child(2) {
			max-width: 20px; } }
	.header__wrapper-menu {
		padding: 0;
		width: 100%;
		padding: 50px 0; }
	.header__wrapper-overlay-menu {
		flex-wrap: wrap;
		text-align: center;
		padding: 50px 30px; }
	.header__wrapper-overlay-widgets {
		border-top: 1px solid $borders-light;
		background-color: initial;
		padding: 50px 0; }
	.header__overlay-menu-back {
		top: 25px;
		left: 25px;
		font-size: 33px !important; } }

@media screen and (max-width: $md) {
	.header {
		padding-top: 20px; } }

/* ======================================================================== */
/* 17. grid */
/* ======================================================================== */
.grid__item_no-fancy {
	&:nth-of-type(3) {
		margin-top: 0 !important; } }
//////////////////////////////////////////
.grid_50 {
	margin-left: -50px;
	margin-right: -50px; }
.grid__item_50 {
	padding-left: 50px;
	padding-right: 50px;
	margin-bottom: 100px;
	&:nth-of-type(3) {
		margin-top: 200px; }
	&:last-of-type {
		margin-bottom: 0; } }
//////////////////////////////////////////
.grid_100 {
	margin-left: -100px;
	margin-right: -100px; }
.grid__item_100 {
	padding-left: 100px;
	padding-right: 100px;
	margin-bottom: 200px;
	&:nth-of-type(3) {
		margin-top: 200px; }
	&:last-of-type {
		margin-bottom: 0; } }
//////////////////////////////////////////
.grid__item_120 {
	padding-left: 120px;
	padding-right: 120px;
	margin-bottom: 240px;
	&:nth-of-type(3) {
		margin-top: 240px; }
	&:last-of-type {
		margin-bottom: 0; } }
//////////////////////////////////////////

.grid_team {
	margin-left: -60px;
	margin-right: -60px; }
.grid__item_team {
	padding-left: 60px;
	padding-right: 60px;
	margin-bottom: 100px;
	&:nth-of-type(3) {
		margin-top: 100px; }
	&:last-of-type {
		margin-bottom: 0; } }

@media screen and (max-width: $xxl) {
	.grid__item_120 {
		padding-left: 80px;
		padding-right: 80px;
		margin-bottom: 160px;
		&:nth-of-type(3) {
			margin-top: 160px; } }
	.grid_100 {
		margin-left: -50px;
		margin-right: -50px; } }


@media screen and (max-width: $xl) {
	//////////////////////////////////////////
	.grid_120 {
		margin-left: -50px;
		margin-right: -50px; }
	.grid__item_120 {
		padding-left: 50px;
		padding-right: 50px;
		margin-bottom: 100px;
		&:nth-of-type(3) {
			margin-top: 100px; } } }


@media screen and (max-width: $md) {
	.grid_120, .grid_100, .grid_50 {
		margin-left: -15px;
		margin-right: -15px; }
	.grid__item_120, .grid__item_100, .grid__item_50 {
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 40px;
		&:nth-of-type(3) {
			margin-top: 0; } } }

/* ======================================================================== */
/* 18. inputFloat */
/* ======================================================================== */
.input-float {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 0; }
.input-float__label {
	position: absolute;
	top: 10px;
	left: 0;
	margin: auto;
	display: block;
	font-size: 16px;
	@include trans1;
	transform-origin: left center;
	cursor: text;
	color: $brown-grey-2; }
.input-float__input {
	display: block;
	width: 100%;
	width: 100%;
	border-bottom: 1px solid $borders-dark;
	border-top: none;
	border-right: none;
	border-left: none;
	outline: none;
	padding: 12px 0 8px;
	@include trans1;
	background-color: transparent;
	border-radius: 0;
	color: inherit; }
.input-float__input_textarea {
	resize: none;
	min-height: 200px; }

.input-float__input_focused, .input-float__input_not-empty {
	+ .input-float__label {
		transform: scale(0.8571) translateY(-1.7rem); } }

.input-float__input_not-empty {
	border-color: $borders-dark; }

/* ======================================================================== */
/* 19. inputSearch */
/* ======================================================================== */
.input-search__input {
	padding-right: 30px; }
.input-search__submit {
	display: inline-block;
	font-size: 18px;
	width: 24px;
	height: 24px;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	background: transparent;
	border: none;
	color: $brown-grey;
	outline: none;
	@include trans1;
	cursor: pointer;
	&:hover {
		color: $dark; }
	&:focus {
		outline: none; } }

/* ======================================================================== */
/* 20. lazy */
/* ======================================================================== */
.lazy {
	background-color: $white;
	animation-duration: 0.9s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-in-out; }
.lazy__img:not(img) {
	background-size: cover;
	background-position: center center; }
.lazy-bg {
	width: 100%;
	height: 100%; }

@keyframes loading {
	0% {
		opacity: 0; }
	to {
		opacity: .3; } }

/* ======================================================================== */
/* 21. menuOverlay */
/* ======================================================================== */
.menu-overlay {
	position: relative;
	@include reset-ul;
	width: 100%;
	max-width: 700px;
	a {
		background-image: none;
		transition: opacity 0.6 ease;
		&.selected {
			opacity: 1 !important; } }
	> li {
		display: block;
		> a {
			display: block;
			font-size: 54px;
			line-height: 2;
			font-weight: 600;
			color: $white;
			span {
				display: inline-block;
				position: relative; } } }
	> li:not(.menu-item-has-children) {
		> a {
			span {
				display: inline-block;
				position: relative; } } } }
.menu-overlay_hover {
	a {
		opacity: .2 !important;
		&:hover, &:focus {
			opacity: 1 !important; } } }

.menu-overlay .sub-menu {
	display: inline-flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	@include reset-ul;
	z-index: 100;
	> li {
		display: block;
		> a {
			display: block;
			position: relative;
			font-size: 33px;
			line-height: 2.25;
			font-weight: 600;
			color: $white;
			span {
				position: relative;
				display: inline-block; } } } }

@media screen and (max-width: $xxl) {
	.menu-overlay {
		> li {
			> a {
				font-size: 43px;
				line-height: 2; } } }
	.menu-overlay .sub-menu {
		> li {
			> a {
				font-size: 26px;
				line-height: 2.33; } } } }

@media screen and (max-width: $md) {
	.menu-overlay {
		max-width: 100%;
		> li {
			> a {
				font-size: 24px;
				line-height: 2.5; } } }
	.menu-overlay .sub-menu {
		> li {
			> a {
				font-size: 19px;
				line-height: 2.33; } } } }

/* ======================================================================== */
/* 22. menu */
/* ======================================================================== */
.menu {
	@include reset-ul;
	word-wrap: normal;
	text-align: right;
	display: inline-block;
	font-size: 14px;
	> li {
		display: inline-block;
		&:not(:last-child) {
			margin-right: 17px; }
		a {
			display: block;
			background-image: none;
			padding: 7px 15px; } }
	> li.menu-item-has-children {
		> a {
			&:after {
				content: '\f107';
				font-family: 'FontAwesome';
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px; } } }

	> li:not(.menu-item-has-children) {
		a {
			position: relative;
			&:hover {
				&:before {
					left: 15px;
					width: calc(100% - 30px); } }
			&:before {
				content: '';
				position: absolute;
				width: 0;
				height: 1px;
				background-color: $dark;
				bottom: 5px;
				right: 15px;
				@include trans1; } } }
	.menu-item-has-children {
		position: relative;
		> a {
			&:hover {
				~ ul {
					opacity: 1;
					visibility: visible;
					transform: translate(0, 0); } } } } }

.menu .sub-menu {
	position: absolute;
	top: 100%;
	right: 17px;
	border-bottom: 4px solid $dark;
	background-color: $white;
	@include reset-ul;
	padding: 15px 0;
	opacity: 0;
	visibility: hidden;
	@include trans1;
	transform: translate(0, 10px);
	box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.02);
	> li {
		display: block;
		white-space: nowrap;
		a {
			display: flex;
			align-items: center;
			padding: 10px 30px;
			background-image: none; } }
	> li.menu-item-has-children {
		> a {
			&:after {
				content: '\f105';
				font-family: 'FontAwesome';
				display: inline-block;
				vertical-align: middle;
				margin-left: auto;
				padding-left: 15px; } } }
	ul {
		top: 0;
		right: calc(100% + 1px);
		transform: translate(10px, 0); }
	&:hover {
		opacity: 1;
		visibility: visible;
		transform: translate(0, 0); } }

/* ======================================================================== */
/* 23. logo */
/* ======================================================================== */
.logo {
	display: block;
	font-family: $font-secondary;
	font-size: 16px;
	line-height: 1.3;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: $black; }
a.logo:focus {
	color: $black;
	opacity: 1; }
a.logo {
	background-image: none; }

/* ======================================================================== */
/* 24. overlay */
/* ======================================================================== */
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: -1; }
.overlay_dark {
	background-color: $dark;
	opacity: .8;
	mix-blend-mode: multiply; }
.overlay_black {
	background-color: $black;
	opacity: .8; }
.overlay_white {
	background-color: $white;
	opacity: .95; }
.overlay_white-solid {
	background-color: $white;
	opacity: 1; }


/* ======================================================================== */
/* 25. post */
/* ======================================================================== */
.post__content {
	h2, h3, h4 {
		margin-top: 0.75em;
		margin-bottom: 0.75em; }
	h5, h6 {
		margin-top: 0.5em;
		margin-bottom: 0.5em; }
	> *:first-child {
		margin-top: 0; } }
.post__media {
	margin-bottom: 2em; }
.post__tags {
	border-top: 2px solid $dark;
	margin-top: 50px;
	padding-top: 20px; }
.post__comments {
	margin-top: 100px; }

/* ======================================================================== */
/* 26. postPreview */
/* ======================================================================== */
.post-preview__wrapper-content {
	> *:first-child {
		margin-top: 0; } }
.post-preview__date {
	font-family: $font-secondary;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 1px;
	text-transform: uppercase; }
.post-preview__header, .post-preview__media, .post-preview__date {
	a {
		background-image: none; } }
.post-preview__header {
	border-bottom: 1px solid $borders-dark;
	margin-bottom: 2em;
	h2 {
		margin-top: 0.75em;
		margin-bottom: 0.75em; } }
.post-preview__categories {
	@include reset-ul;
	li {
		display: inline-block; } }
.post-preview__meta {
	margin-bottom: 1em; }
.post-preview__wrapper-meta {
	margin-top: 5px; }
.post-preview__wrapper-readmore {
	text-align: right;
	margin-top: 2em; }

/* ======================================================================== */
/* 27. parallax */
/* ======================================================================== */
.art-parallax, [data-art-parallax] {
	position: relative;
	overflow: hidden;
	> img {
		width: 100%;
		height: 100%;
		object-fit: cover; } }
.art-parallax__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center; }

/* ======================================================================== */
/* 28. preloader */
/* ======================================================================== */
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0;
	visibility: hidden;
	z-index: 400; }
.preloader__curtain {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }
.preloader__curtain_black {
	background-color: $dark-1; }
.preloader__curtain_white {
	background-color: $very-light-pink; }

/* ======================================================================== */
/* 29. projectBackgrounds */
/* ======================================================================== */
.project-backgrounds {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-color: $dark-2;
	opacity: 0;
	visibility: hidden; }
.project-backgrounds__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	transform: scale(1.05);
	&.active, &.selected {
		opacity: 1;
		visibility: visible;
		transform: scale(1); } }
.project-backgrounds__overlay {
	@include trans1;
	z-index: 0 !important; }

/* ======================================================================== */
/* 30. scroll */
/* ======================================================================== */
.smooth-scroll {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh; }
[data-os-animation] {
	opacity: 0;
	visibility: hidden;
	.split-text__line {
		a {
			background-image: linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 1px, rgba(0,0,0,0) 100px); } } }
[data-os-animation="animated"] {
	.split-text__line {
		a {
			background-image: linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, $brown-grey-2 0px, rgba(0,0,0,0) 1px, rgba(0,0,0,0) 100px); } } }

/* ======================================================================== */
/* 31. section */
/* ======================================================================== */
$margin-small: 100px;
$margin-normal: 200px;
$margin-large: 300px;

@mixin setMargins($m-small, $m-normal, $m-large) {
	.section_pt-large {
		padding-top: $m-large; }
	.section_pt {
		padding-top: $m-normal; }
	.section_pt-small {
		padding-top: $m-small; }
	.section_pb {
		padding-bottom: $m-normal; }
	.section_pb-small {
		padding-bottom: $m-small; }
	.section_pb-large {
		padding-bottom: $m-large; }
	.section_mt-large {
		margin-top: $m-large; }
	.section_mt {
		margin-top: $m-normal; }
	.section_mt-small {
		margin-top: $m-small; }
	.section_mb {
		margin-bottom: $m-normal; }
	.section_mb-small {
		margin-bottom: $m-small; }
	.section_mb-large {
		margin-bottom: $m-large; } }

.section {
	position: relative; }
.section-fullheight {
	display: flex; }
.section-fullheight__inner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	padding-top: $margin-normal;
	padding-bottom: $margin-normal; }

@include setMargins($margin-small, $margin-normal, $margin-large);

.section__header {
	h2 {
		margin-bottom: 0.75em; }
	h3 {
		&:last-of-type {
			margin-bottom: 0; } } }
.section__headline {
	display: block;
	width: 80px;
	height: 1px;
	background-color: $dark;
	margin-bottom: 10px; }
@for $i from 1 through 9 {
	.section_h-#{$i * 100} {
		height: #{$i * 100}px;
		max-height: 100vh; } }
.section_h-100vh {
	height: 100vh; }
.section__content {
	margin-top: 50px; }
.section_w-container-right {
	max-width: 100%;
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 25px );
	margin-left: auto;
	text-align: left; }
.section_w-container-left {
	max-width: 100%;
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 25px );
	margin-right: auto;
	text-align: right; }

@media screen and (max-width: $xl) {

	$margin-small: 70px;
	$margin-normal: 140px;
	$margin-large: 240px;
	@include setMargins($margin-small, $margin-normal, $margin-large);

	.section-fullheight__inner {
		padding-top: $margin-normal;
		padding-bottom: $margin-normal; }
	.section__headline {
		max-width: 80px; }
	.section_w-container-right, .section_w-container-left {
		width: 100%;
		text-align: center; } }

@media screen and (max-width: $md) {

	$margin-small: 50px;
	$margin-normal: 100px;
	$margin-large: 120px;
	@include setMargins($margin-small, $margin-normal, $margin-large);

	.section-fullheight__inner {
		padding-top: $margin-large;
		padding-bottom: $margin-large; }
	.section__content {
		margin-top: 30px; } }

@media screen and (max-width: $sm) {

	$margin-small: 40px;
	$margin-normal: 80px;
	$margin-large: 100px;
	@include setMargins($margin-small, $margin-normal, $margin-large);

	.section-fullheight__inner {
		padding-top: $margin-normal;
		padding-bottom: $margin-normal; }
	.section__headline {
		max-width: 50px; }

	@for $i from 1 through 9 {
		.section_h-#{$i * 100} {
			max-height: 70vh; } } }

/* ======================================================================== */
/* 32. section404 */
/* ======================================================================== */
.section-404__header {
	p {
		margin-top: 3em; } }
.section-404__wrapper-button {
	margin-top: 30px; }
.section-404__big {
	font-size: 300px;
	font-weight: bold;
	line-height: 1;
	opacity: .05;
	transform: rotate(90deg);
	transform-origin: right center;
	white-space: nowrap;
	position: absolute;
	top: calc(100% - 240px);
	right: 240px; }

@media screen and (max-width: $xxl) {
	.section-404__big {
		top: calc(100% - 180px);
		right: 180px; } }

@media screen and (max-width: $xl) {
	.section-404__big {
		font-size: 200px;
		top: calc(100% - 120px);
		right: 120px; } }

@media screen and (max-width: $md) {
	.section-404 {
		text-align: center;
		.section__headline {
			margin-left: auto;
			margin-right: auto; } }
	.section-404__big {
		position: relative;
		top: auto;
		right: auto;
		transform: none;
		font-size: 150px; } }

/* ======================================================================== */
/* 33. sectionCTA */
/* ======================================================================== */
.section-cta__wrapper-button {
	margin-top: 35px; }
.section-cta__headline {
	width: 1px;
	height: 100px;
	background-color: $dark;
	margin-bottom: 55px;
	margin-left: auto;
	margin-right: auto; }

@media screen and (max-width: $md) {
	.section-cta__wrapper-button {
		margin-top: 25px; } }

/* ======================================================================== */
/* 34. sectionBlog */
/* ======================================================================== */
.section-blog__wrapper-post {
	margin-bottom: 100px;
	&:last-child {
		margin-bottom: 0; } }

/* ======================================================================== */
/* 35. sectionContent */
/* ======================================================================== */
.section-content__inner > *:last-child {
	margin-bottom: 0; }
.section-content__main-content {
	margin-top: 100px;
	margin-bottom: -50px; }
.section-content__wrapper-item {
	margin-bottom: 50px; }
.section-content__main-content_no-header {
	margin-top: 0 !important; }
.section-content__inner_header {
	margin-bottom: 1em; }

@media screen and (max-width: $md) {
	.section-content__main-content {
		margin-top: 60px;
		margin-bottom: -30px; }
	.section-content__wrapper-item {
		margin-bottom: 30px; } }

/* ======================================================================== */
/* 36. sectionComposition */
/* ======================================================================== */
.section-composition {
	display: flex; }
.section-composition__image {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 200px 120px;
	flex: 1 1 auto; }
.section-composition__content {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	width: 100%;
	max-width: 640px;
	flex: 1 0 33vw;
	padding: 200px 120px;
	h6 {
		font-size: 20px; } }
.section-composition__counter {
	align-self: flex-start;
	font-family: $font-secondary;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-bottom: 20px; }

@media screen and (max-width: $xxl) {
	.section-composition__image, .section-composition__content {
		padding: 120px 80px; }
	.section-composition__counter {
		font-size: 12px; } }

@media screen and (max-width: $xl) {
	.section-composition__image, .section-composition__content {
		padding: 80px 50px; } }

@media screen and (max-width: $md) {
	.section-composition {
		flex-direction: column;
		justify-content: center; }
	.section-composition__content {
		align-items: center;
		justify-content: center;
		max-width: 100%;
		flex: 1 0 100%;
		order: -1;
		padding: 80px 30px 0;
		background-color: initial; }
	.section-composition__image {
		background-color: initial; }
	.section-composition__counter {
		align-self: unset;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		margin-bottom: 20px; }
	.section-composition__image {
		padding: 30px 30px 80px; }
	.section-composition__content h6 {
		font-size: 16px; } }

/* ======================================================================== */
/* 37. sectionFigures */
/* ======================================================================== */
.section-figures {
	.section-figures__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		padding: 200px 120px;
		h5 {
			width: 100%;
			margin-top: 1em;
			margin-bottom: 0; } }
	.section-figures__item_no-paddings {
		padding: 0; } }

@media screen and (max-width: $xxl) {
	.section-figures {
		.section-figures__item:not(.section-figures__item_no-paddings) {
			padding: 120px 80px; } } }

@media screen and (max-width: $xl) {
	.section-figures {
		.section-figures__item:not(.section-figures__item_no-paddings) {
			padding: 80px 50px; } } }

/* ======================================================================== */
/* 38. sectionFullscreenSlider */
/* ======================================================================== */
.section-fullscreen-slider.color-white {
	.slider__dot {
		&:before {
			background-color: $brown-grey; }
		&:after {
			border-color: $white; } }
	.slider__dot_active {
		&:before {
			background-color: $white;
			border-color: $white; } } }

.section-fullscreen-slider__inner {
	padding-top: 0;
	padding-bottom: 0; }

/* ======================================================================== */
/* 39. sectionImage */
/* ======================================================================== */
.section-image__content {
	margin-top: 40px; }
.section-image {
	h5 {
		display: block;
		width: 100%;
		margin-top: 1em;
		margin-bottom: 0; } }

/* ======================================================================== */
/* 40. sectionIntro */
/* ======================================================================== */
.section-intro.text-center {
	.section-intro__header, .section-intro__headline {
		margin-left: auto;
		margin-right: auto; } }
.section-intro__header {
	max-width: 1200px;
	h1 {
		margin-top: 2em;
		margin-bottom: 1em;
		font-size: 43px;
		line-height: 1.67; } }
.section-intro__headline {
	width: 100%;
	max-width: 120px;
	height: 1px;
	background-color: $black; }

@media screen and (max-width: $xl) {
	.section-intro__header {
		h1 {
			font-size: 33px; } }
	.section-intro__headline {
		max-width: 80px; } }
@media screen and (max-width: $md) {
	.section-intro__header {
		h1 {
			font-size: 24px; } } }
@media screen and (max-width: $sm) {
	.section-intro__header {
		h1 {
			margin-top: 40px;
			font-size: 21px; } }
	.section-intro__headline {
		max-width: 50px; } }

/* ======================================================================== */
/* 41. sectionMap */
/* ======================================================================== */
.section-map__wrapper-form {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 80px;
	right: 120px;
	bottom: 80px;
	padding: 100px 80px; }

@media screen and (max-width: $xxl) {
	.section-map__wrapper-form {
		right: 80px;
		padding: 80px 60px; } }

@media screen and (max-width: $xl) {
	.section-map__wrapper-form {
		right: 50px; } }

@media screen and (max-width: $md) {
	.section-map__wrapper-form {
		position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		padding: 80px 30px; } }

/* ======================================================================== */
/* 42. sectionMasthead */
/* ======================================================================== */
.section-masthead__header {
	max-width: 960px;
	h1 {
		margin-top: 1.75em;
		margin-bottom: 0.5em; }
	h2 {
		margin-top: 100px; } }
.section-masthead_fullheight {
	h1 {
		margin-top: 0;
		margin-bottom: 0.5em; } }
.section-masthead_fullheight-halfscreen {
	.section-masthead__background {
		position: relative;
		min-height: 100vh; } }
.section-masthead_fullheight-halfscreen-reverse {
	flex-direction: row-reverse; }
.section-masthead__wrapper-property {
	margin-right: 70px;
	margin-bottom: 70px;
	&:last-of-type {
		margin-right: 0; } }
.section-masthead__properties {
	margin-top: 70px;
	margin-bottom: -70px; }
.section-masthead__background_bottom {
	margin-top: 100px; }
.section-masthead__background_fullscreen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin-top: 0; }
.section-masthead__overlay {
	z-index: 0;
	opacity: .6; }
.section-masthead__properties_bottom {
	position: absolute;
	bottom: 70px;
	left: 120px; }
.section-masthead__content {
	width: 100%; }
.section-masthead__inner {
	position: relative;
	z-index: 50; }

@media screen and(max-width: $xxl) {
	.section-masthead__properties_bottom {
		left: 80px;
		bottom: 50px; } }

@media screen and (max-width: $xl) {
	.section-masthead__wrapper-property {
		margin-right: 30px; }
	.section-masthead__properties_bottom {
		left: 50px; }
	.section-masthead__background_bottom {
		margin-top: 80px; } }


@media screen and (max-width: $md) {
	.section-masthead__wrapper-property {
		margin-right: 0; }
	.section-masthead__wrapper-property {
		margin-bottom: 30px; }
	.section-masthead__properties {
		margin-top: 30px;
		margin-bottom: -30px; }
	.section-masthead__properties_bottom {
		position: relative;
		left: auto;
		bottom: auto; }
	.section-masthead_fullheight-halfscreen {
		padding-top: 100px;
		flex-wrap: wrap;
		.section-fullheight__inner {
			min-height: unset;
			padding-top: 0;
			padding-bottom: 0; }
		.section-masthead__background {
			margin-top: 80px; } }
	.section-masthead_fullheight {
		h1 {
			margin-top: 1.75em;
			margin-bottom: 0.5em; } }
	.section-masthead__background_bottom {
		width: 100%;
		margin-top: 80px; } }


@media screen and (max-width: $sm) {
	.section-masthead__header {
		h1 {
			margin-top: 40px; }
		h2 {
			margin-top: 30px;
			font-size: 21px; } }
	.section-masthead_fullheight-halfscreen {
		padding-top: 80px;
		.section-masthead__background {
			min-height: 0;
			height: 900px;
			max-height: 70vh;
			margin-top: 60px; } }
	.section-masthead__background_bottom {
		margin-top: 60px; } }

/* ======================================================================== */
/* 43. sectionNavProjects */
/* ======================================================================== */
.section-nav-projects {
	background-color: $brown-grey-2;
	.section-nav-projects__inner_prev {
		padding-left: 120px;
		padding-right: 80px; }
	.section-nav-projects__inner_next {
		padding-left: 80px;
		padding-right: 120px; } }
.section-nav-projects__inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding-top: 200px;
	padding-bottom: 200px;
	height: 100%;
	color: $white;
	z-index: 50;
	background-image: none;
	h3 {
		margin-top: 0;
		margin-bottom: 0; }
	&:nth-of-type(odd) {
		box-shadow: 1px 0px 0px 0px rgba(255, 255, 255, 0.1); }
	&:hover {
		opacity: 1;
		color: $white; } }
.section-nav-projects__arrow {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border-radius: 100%;
	border: 1px solid $borders-light;
	> i {
		font-size: 32px; } }
.section-nav-projects__backgrounds {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden; }
.section-nav-projects__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	background-size: cover;
	background-position: center center;
	transition: all 0.3s ease-in-out;
	transform: scale(1.05);
	&.active, &.selected {
		opacity: 1;
		visibility: visible;
		transform: scale(1); } }
.section-nav-projects__overlay {
	z-index: 0; }
.section-nav-projects__label {
	position: absolute;
	@include small-caps; }
.section-nav-projects__label_next {
	top: 60px;
	left: 80px; }
.section-nav-projects__label_prev {
	bottom: 60px;
	right: 80px; }

@media screen and (max-width: $xxl) {
	.section-nav-projects {
		.section-nav-projects__inner_prev {
			padding-left: 80px;
			padding-right: 80px; }
		.section-nav-projects__inner_next {
			padding-left: 80px;
			padding-right: 80px; } }
	.section-nav-projects__arrow {
		width: 60px;
		height: 60px; }
	.section-nav-projects__arrow > i {
		font-size: 24px; } }

@media screen and (max-width: $xl) {
	.section-nav-projects {
		.section-nav-projects__inner_prev {
			padding-left: 50px;
			padding-right: 60px; }
		.section-nav-projects__inner_next {
			padding-left: 60px;
			padding-right: 50px; } }
	.section-nav-projects__label_next {
		left: 60px; }
	.section-nav-projects__label_prev {
		right: 60px; }
	.section-nav-projects__inner {
		padding-top: 160px;
		padding-bottom: 160px; } }

@media screen and (max-width: $md) {
	.section-nav-projects__arrow {
		width: 50px;
		height: 50px; }
	.section-nav-projects__arrow > i {
		font-size: 20px; }
	.section-nav-projects__inner {
		padding-top: 120px;
		padding-bottom: 120px; } }

@media screen and (max-width: $sm) {
	.section-nav-projects {
		.section-nav-projects__inner_prev {
			padding-right: 30px;
			padding-left: 30px; }
		.section-nav-projects__inner_next {
			padding-right: 30px;
			padding-left: 30px; } }
	.section-nav-projects__label {
		position: relative;
		left: auto;
		right: auto;
		bottom: auto;
		top: auto;
		margin-bottom: 1em; }
	.section-nav-projects__inner {
		flex-direction: column;
		align-items: center;
		padding-top: 80px;
		padding-bottom: 80px;
		h3 {
			margin-bottom: 2em; } }
	.section-nav-projects__arrow {
		order: 2; }
	.section-nav-projects__inner {
		&:nth-of-type(odd) {
			box-shadow: 0px 0px 1px 0px rgba(255, 255, 255, 0.1); } } }

/* ======================================================================== */
/* 44. sectionVideo */
/* ======================================================================== */
.section-video {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%; }
.section-video__link {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-image: none;
	width: 100%;
	z-index: 100;
	cursor: none;
	&:hover {
		opacity: 1;
		.section-video__icon {
			&:after {
				transform: scale(1); } } }
	&:focus {
		opacity: 1; } }
.section-video__icon {
	position: relative;
	display: inline-flex !important;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border-radius: 100%;
	margin-top: 30px;
	&:focus {
		outline: none; }
	&:after {
		content: '';
		display: block;
		border-radius: 100%;
		border: 1px solid $brown-grey;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: scale(0);
		@include trans1; }
	i {
		font-size: 32px;
		line-height: 2; } }

@media screen and (max-width: $sm) {
	.section-video__icon {
		&:after {
			transform: scale(1); } } }

/* ======================================================================== */
/* 45. select */
/* ======================================================================== */
select {
	display: block;
	border: 1px solid $borders-dark;
	padding: 10px 35px 10px 15px;
	background-color: transparent;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url('../img/general/select.png');
	background-position: right 15px bottom 50%;
	background-repeat: no-repeat;
	background-size: 6px 4px; }

/* ======================================================================== */
/* 46. sidebar */
/* ======================================================================== */
.sidebar_no-margin-last-widget {
	.widget {
		&:last-child {
			margin-bottom: 0; } } }
.sidebar {
	.widget {
		margin-bottom: 50px; } }

@media only screen and (max-width: $md) {
	.sidebar {
		margin-top: 80px; } }

@media only screen and (max-width: $sm) {
	.sidebar {
		margin-top: 40px; } }

/* ======================================================================== */
/* 47. slider */
/* ======================================================================== */
.slider {
	max-width: 100%; }
.slider_vertical-centered {
	.swiper-wrapper {
		align-items: center; } }
.slider__caption {
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 30px; }

.slider__counter {
	@include small-caps;
	height: 16px; }
.slider__counter_current {
	margin-right: 30px; }
.slider__counter_total {
	margin-left: 30px;
	color: $brown-grey-2; }

.slider__progress {
	display: inline-flex;
	align-items: center;
	margin-top: 40px; }
.slider__progressbar {
	position: relative;
	width: 180px;
	height: 1px;
	background: $brown-grey; }
.slider__progressbar-fill {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: $dark;
	transform-origin: left center;
	@include trans1; }

.slider__dot {
	position: relative;
	width: 18px;
	height: 18px;
	margin: 0 16px;
	cursor: pointer;
	outline: none;
	display: inline-block;
	&:before {
		content: '';
		display: block;
		width: 4px;
		height: 4px;
		border-radius: 100%;
		background-color: $brown-grey;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		@include trans1; }
	&:after {
		content: '';
		display: block;
		border-radius: 100%;
		border: 1px solid $brown-grey;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: scale(0);
		@include trans1; }
	&:hover {
		&:after {
			transform: scale(1); } } }
.slider__dot_active {
	&:before {
		background-color: $dark; }
	&:after {
		transform: scale(1);
		border-color: $dark; } }
.slider__backgrounds {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100vw;
	height: 100vh;
	overflow: hidden; }
.slider__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	transform: scale(1.05);
	&.active, &.selected {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		~ .slider__background-overlay {
			opacity: .6; } } }
.slider__background-overlay {
	opacity: 0;
	@include trans1;
	z-index: 0 !important; }

.slider__arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border-radius: 100%;
	cursor: pointer;
	@include trans1;
	&:focus {
		outline: none; }
	&:after {
		content: '';
		display: block;
		border-radius: 100%;
		border: 1px solid $brown-grey;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: scale(0);
		@include trans1; }
	&:hover {
		&:after {
			transform: scale(1); } }
	i {
		font-size: 32px;
		line-height: 2; }
	&.swiper-button-disabled {
		opacity: 0.3;
		&:hover {
			&:after {
				transform: scale(0); } } } }
.slider__arrow-inner {
	position: relative; }

@media screen and (max-width: $xl) {
	.slider__caption {
		font-size: 16px;
		margin-bottom: 20px; }
	.slider__arrow {
		width: 60px;
		height: 60px;
		i {
			font-size: 24px; } } }

@media screen and (max-width: $md) {
	.slider__progressbar {
		width: 140px; }
	.slider__dot {
		margin: 0 8px; }
	.slider__progress {
		margin-top: 25px; }
	.slider__counter_total {
		margin-left: 15px; }
	.slider__counter_current {
		margin-right: 15px; } }

/* ======================================================================== */
/* 48. sliderFullscreen */
/* ======================================================================== */
.slider-halfscreen_fullscreen {
	height: 100vh;
	flex-direction: column;
	.slider-halfscreen__images {
		width: 100%;
		height: 100%; }
	.slider-halfscreen__content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: auto; }
	.slider-halfscreen__content-inner {
		padding: 0 30px;
		text-align: center;
		h2 {
			margin-top: 0;
			margin-bottom: 0;
			width: 100%; } }
	.slider-halfscreen__wrapper-link {
		margin-top: 40px;
		bottom: auto;
		left: auto;
		position: relative; }
	.slider-halfscreen__link {
		display: inline-flex; }
	.slider-halfscreen__header p {
		margin-left: auto;
		margin-right: auto; } }

/* ======================================================================== */
/* 49. sliderHalfScreen */
/* ======================================================================== */
.slider-halfscreen {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 100vh;
	overflow: hidden; }
.slider-halfscreen__images {
	width: 50%;
	height: 100%;
	margin: 0; }
.slider-halfscreen__content {
	width: 50%;
	height: 100%;
	margin: 0;
	max-height: 100%; }
.slider-halfscreen__images-slide {
	overflow: hidden; }
.slider-halfscreen__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover; }
.slider-halfscreen__content-slide {
	height: 100%; }
.slider-halfscreen__content-inner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 120px 180px 120px 120px;
	h2 {
		font-size: 54px;
		font-weight: 600;
		line-height: 1.59; } }
.slider-halfscreen__header {
	width: 100%;
	p {
		max-width: 500px;
		font-size: 16px; } }
.slider-halfscreen__wrapper-link {
	position: absolute;
	bottom: 80px;
	left: 120px; }
.slider-halfscreen__link {
	display: flex;
	align-items: center;
	background-image: none;
	span {
		@include small-caps; }
	&:hover {
		opacity: 1; } }
.slider-halfscreen__link-line {
	background-color: $white;
	width: 60px;
	height: 1px;
	margin-right: 20px; }
.slider-halfscreen__arrows {
	display: flex;
	flex-direction: column;
	position: absolute;
	right: 90px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 60; }
.slider-halfscreen__images-slide-inner {
	width: 100%;
	height: 100%; }
.slider-halfscreen__overlay {
	z-index: 1 !important; }

@media screen and (max-width: $xxl) {
	.slider-halfscreen__content-inner {
		padding: 80px 120px 80px 80px;
		h2 {
			font-size: 43px; } }
	.slider-halfscreen__wrapper-link {
		bottom: 60px;
		left: 80px; }
	.slider-halfscreen__arrows {
		right: 50px; } }

@media screen and (max-width: $xl) {
	.slider-halfscreen__content-inner {
		padding: 50px 90px 50px 50px;
		h2 {
			font-size: 28px; } }
	.slider-halfscreen__wrapper-link {
		bottom: 50px;
		left: 50px; }
	.slider-halfscreen__arrows {
		right: 30px; } }

@media screen and (max-width: $md) {
	.slider-halfscreen {
		height: 100vh;
		flex-direction: column; }
	.slider-halfscreen__images {
		width: 100%;
		height: 100%; }
	.slider-halfscreen__content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: auto; }
	.slider-halfscreen__content-inner {
		padding: 0 30px;
		text-align: center;
		h2 {
			margin-top: 0;
			margin-bottom: 0;
			width: 100%; } }
	.slider-halfscreen__wrapper-link {
		margin-top: 15px;
		bottom: auto;
		left: auto;
		position: relative; }
	.slider-halfscreen__link {
		justify-content: center; }
	.slider-halfscreen__arrows {
		left: 0;
		right: 0;
		top: auto;
		bottom: 15px;
		transform: none;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex: 0 1 80px;
		width: 100%;
		padding: 0 15px; }
	.slider-halfscreen__header p {
		margin-left: auto;
		margin-right: auto; } }

/* ======================================================================== */
/* 50. sliderHeadings */
/* ======================================================================== */
.slider-headings {
	text-align: center;
	height: 100%;
	.slider__dot {
		&:before {
			background-color: $dark; }
		&:after {
			border-color: $dark; } } }
.slider-headings__footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30px;
	margin: 0 auto;
	text-align: center;
	z-index: 60; }
.slider-headings__slide {
	display: flex;
	align-items: center;
	justify-content: center;
	h2 {
		font-size: 68px; } }
.slider-headings__link {
	display: block;
	padding-right: 15px;
	padding-left: 15px;
	background-image: none;
	&:hover {
		opacity: 1;
		color: $white; } }
.slider-headings__progress {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-weight: bold;
	z-index: -1;
	opacity: .05;
	height: 300px;
	margin-top: 0;
	@include trans1; }
.slider-headings__counter {
	font-size: 300px;
	line-height: 300px;
	height: 300px;
	overflow: hidden; }
.slider-headings__arrow {
	position: absolute !important;
	top: 50%;
	transform: translateY(-50%);
	z-index: 60; }
.slider-headings__arrow_prev {
	left: 80px; }
.slider-headings__arrow_next {
	right: 80px; }

@media screen and (max-width: $xxl) {
	.slider-headings__arrow_prev {
		left: 45px; }
	.slider-headings__arrow_next {
		right: 45px; }
	.slider-headings__slide {
		h2 {
			font-size: 54px; } }
	.slider-headings__counter {
		font-size: 250px;
		line-height: 250px;
		height: 250px; } }

@media screen and (max-width: $xl) {
	.slider-headings__arrow_prev {
		left: 25px; }
	.slider-headings__arrow_next {
		right: 25px; } }

@media screen and (max-width: $md) {
	.slider-headings__slide {
		h2 {
			font-size: 28px;
			line-height: 1.3; } }
	.slider-headings__arrow {
		&:after {
			transform: scale(1) !important; } }
	.slider-headings__arrow_prev {
		left: 30px; }
	.slider-headings__arrow_next {
		right: 30px; }
	.slider-headings__counter {
		font-size: 150px;
		line-height: 150px;
		height: 150px; } }

@media screen and (max-width: $sm) {
	.slider-headings__arrow {
		display: none; } }

/* ======================================================================== */
/* 51. sliderTestimonials */
/* ======================================================================== */
.slider-testimonials__slide {
	display: flex;
	align-items: center;
	padding: 50px 0;
	blockquote {
		font-size: 20px;
		line-height: 1.75;
		font-style: italic;
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 0; } }
.slider-testimonials__footer {
	border-top: 1px solid $borders-dark;
	text-align: center;
	padding-top: 30px; }
.slider-testimonials__wrapper-avatar {
	border-radius: 100%;
	overflow: hidden;
	max-width: 164px;
	max-height: 164px;
	flex: 1 0 auto;
	margin-right: 50px; }
.slider-header-testimonials {
	border-bottom: 1px solid $borders-dark;
	padding-bottom: 25px;
	&:before, &:after {
		content: '';
		display: block;
		height: 100%;
		width: 50px;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 50; }
	&:before {
		left: 0;
		background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); }
	&:after {
		right: 0;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); } }

.slider-header-testimonials__slide {
	text-align: center;
	cursor: pointer;
	span {
		display: block;
		opacity: .3;
		font-size: 20px;
		font-weight: bold;
		white-space: nowrap;
		@include trans1; }
	&.swiper-slide-active {
		span {
			opacity: 1; } } }

@media only screen and (max-width: $md) {
	.slider-testimonials__wrapper-avatar {
		max-width: 80px;
		max-height: 80px;
		margin-right: 0;
		margin-bottom: 1em; }
	.slider-testimonials__slide {
		flex-direction: column;
		text-align: center;
		padding: 30px 0;
		blockquote {
			font-size: 16px; } }
	.slider-header-testimonials {
		padding-bottom: 15px; }
	.slider-header-testimonials__slide span {
		font-size: 16px; } }

/* ======================================================================== */
/* 52. sliderText */
/* ======================================================================== */
.slider-text {
	width: 100%;
	height: 100%; }
.slider-text__upper {
	height: 50%;
	.slider-text__slide {
		align-items: flex-end; } }
.slider-text__lower {
	height: 50%;
	.slider-text__slide {
		align-items: flex-start; } }
.slider-text__slide {
	flex: 1 1 auto;
	padding: 15px 0;
	display: flex;
	justify-content: center;
	h2 {
		font-size: 54px;
		font-weight: 600;
		line-height: 1.59;
		white-space: nowrap;
		margin-bottom: 0; } }
.slider-text__header {
	position: relative; }
.slider-text__line {
	width: 120px;
	height: 2px;
	background-color: $dark;
	margin-left: 30px;
	margin-right: 30px; }
.slider-text__counter {
	position: absolute;
	top: -15px;
	right: 100%;
	@include small-caps;
	color: $brown-grey-2;
	white-space: nowrap; }
.slider-text__link {
	display: flex;
	align-items: center;
	background-image: none;
	&:hover {
		opacity: 1;
		color: $white; } }
.slider-text__helper {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30px;
	margin: 0 auto;
	text-align: center; }
.slider-text__helper-label-normal {
	@include small-caps;
	margin: 0 50px;
	padding-bottom: 2px;
	overflow: hidden; }
.slider-text__helper-label-view {
	position: absolute;
	left: 0;
	right: 0;
	@include small-caps;
	padding-bottom: 2px;
	overflow: hidden; }
.slider-text__helper__inner {
	position: relative;
	display: inline-flex;
	align-items: center; }

@media screen and (max-width: $xxl) {
	.slider-text__slide {
		h2 {
			font-size: 43px; } } }

@media screen and (max-width: $md) {
	.slider-text__slide {
		h2 {
			font-size: 28px; } } }

@media screen and (max-width: $sm) {
	.slider-text__slide {
		text-align: center;
		flex: 1 0 100%;
		h2 {
			white-space: unset;
			line-height: 1.3; } }
	.slider-text__line {
		display: none; }
	.slider-text__counter {
		position: relative;
		top: auto;
		left: auto;
		margin-bottom: 15px;
		right: auto; } }

/* ======================================================================== */
/* 53. social */
/* ======================================================================== */
.social {
	@include reset-ul;
	margin: -8px; }
.social__item {
	display: inline-block;
	margin: 8px;
	a {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: transparent;
		width: 48px;
		height: 48px;
		font-size: 16px;
		border-radius: 100%;
		background-image: none;
		&:after {
			content: '';
			display: block;
			border-radius: 100%;
			border: 1px solid $brown-grey;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: scale(0);
			@include trans1; }
		&:hover {
			opacity: 1;
			&:after {
				transform: scale(1); } } } }

@media screen and (max-width: $md) {
	.social__item a {
		width: 40px;
		height: 40px;
		font-size: 14px; } }

/* ======================================================================== */
/* 54. spinner */
/* ======================================================================== */
$offset: 202;
$duration: 1.2s;

.spinner {
	position: fixed;
	left: 120px;
	bottom: calc(var(--fix-bar-vh, 30px) + 15px);
	width: 30px;
	height: 30px;
	z-index: 10000;
	animation: rotator $duration ease-in-out infinite;
	opacity: 0;
	visibility: hidden; }

@keyframes rotator {
	0% {
		transform: rotate(0deg); }
	100% {
		transform: rotate(270deg); } }

.spinner__path {
	stroke: #b0b0b0;
	stroke-dasharray: $offset;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash $duration ease-in-out infinite; }

@keyframes dash {
	0% {
		stroke-dashoffset: $offset;
		transform: rotate(0deg); }
	50% {
		stroke-dashoffset: $offset / 4;
		transform: rotate(135deg); }
	100% {
		stroke-dashoffset: $offset;
		transform: rotate(450deg); } }

@media screen and (max-width: $xxl) {
	.spinner {
		left: 80px; } }
@media screen and (max-width: $xl) {
	.spinner {
		left: 30px; } }

/* ======================================================================== */
/* 55. tags */
/* ======================================================================== */
.tagcloud {
	ul {
		@include reset-ul;
		li {
			display: inline-block !important;
			margin-bottom: 0 !important; } }
	a {
		display: inline-block;
		font-size: 12px !important;
		line-height: 1;
		color: $dark;
		background-color: $very-light-pink;
		padding: 11px 15px;
		margin-bottom: 6px;
		margin-right: 4px;
		border-radius: 100px;
		background-image: none;
		&:hover {
			background-color: $light-blue-grey;
			opacity: 1; } } }

/* ======================================================================== */
/* 56. splitText */
/* ======================================================================== */
.split-text, .split-chars {
	opacity: 0;
	visibility: hidden; }
.split-text__line {
	overflow: hidden; }
.split-chars__line {
	display: inline;
	overflow: hidden; }

/* ======================================================================== */
/* 57. typography */
/* ======================================================================== */
h1, h2, h3, h4, h5 {
	font-family: $font-primary; }
h6 {
	font-family: $font-secondary; }

h1 {
	font-size: 54px;
	font-weight: 600;
	line-height: 1.59; }
h2 {
	font-size: 33px;
	font-weight: 600;
	line-height: 1.5; }
h3 {
	font-size: 27px;
	font-weight: 300;
	line-height: 1.67;
	margin-bottom: 1em; }
h4 {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.67; }
h5 {
	font-size: 20px;
	font-weight: 600;
	line-height: 1.67; }
h6 {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 1px;
	text-transform: uppercase; }
p {
	font-weight: 300;
	margin-top: 1em;
	margin-bottom: 1em; }
strong {
	font-weight: 600; }
small {
	font-size: 14px; }
.heading-light {
	font-weight: 300; }
a {
	@include trans1;
	color: inherit;
	background-image: linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, $brown-grey-2 0px, rgba(0,0,0,0) 1px, rgba(0,0,0,0) 100px);
	&:hover, &:focus {
		text-decoration: none;
		color: inherit;
		opacity: .3; }
	&:focus {
		color: inherit;
		outline: none; } }

@media screen and (max-width: $xxl) {
	h1 {
		font-size: 43px; }
	h2 {
		font-size: 26px; }
	h3 {
		font-size: 24px; }
	h4 {
		font-size: 19px; }
	h5 {
		font-size: 16px; }
	h6 {
		font-size: 16px; } }
@media screen and (max-width: $md) {
	h1 {
		font-size: 28px; }
	h2 {
		font-size: 24px; }
	h3 {
		font-size: 19px; }
	h4 {
		font-size: 17px; }
	h5 {
		font-size: 16px; }
	h6 {
		font-size: 16px; }
	small {
		display: inline-block;
		font-size: 12px; } }

/* ======================================================================== */
/* 58. utilities */
/* ======================================================================== */
.text-left {
	text-align: left !important;
	.section__headline {
		margin-right: auto; } }
.text-center {
	text-align: center !important;
	.section__headline {
		margin-left: auto;
		margin-right: auto; } }
.text-right {
	text-align: right !important;
	.section__headline {
		margin-left: auto; } }
.row-80 {
	margin-left: -40px;
	margin-right: -40px; }
.overflow {
	position: relative;
	overflow: hidden; }
.overflow__curtain {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50; }
.overflow__content {
	width: 100%;
	height: 100%; }
.position-relative {
	position: relative; }
.color-white {
	color: $white !important; }
.color-black {
	color: $black0 !important; }
.color-dark {
	color: $dark !important; }
.blurred {
	filter: blur(7px); }
.of-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	font-family: 'object-fit: cover;'; }
.of-contain {
	width: 100%;
	height: 100%;
	object-fit: contain;
	font-family: 'object-fit: contain;'; }
.split-line {
	overflow: hidden; }
.hidden {
	opacity: 0;
	visibility: hidden; }
.no-gutters {
	padding-left: 0;
	padding-right: 0; }
.grayscale {
	filter: grayscale(70%); }
.h-100 {
	height: 100%; }
.lockhover {
	.menu-overlay {
		> li {
			> a:not(.selected) {
				opacity: .2;
				pointer-events: none; } } }
	.menu-overlay .sub-menu {
		> li {
			> a:not(.selected) {
				opacity: .2;
				pointer-events: none; } } } }
#js-webgl {
	display: none;
	width: 1px;
	height: 1px; }

/* ======================================================================== */
/* 59. widget */
/* ======================================================================== */
.widget {
	p {
		&:last-of-type {
			margin-bottom: 0; } }
	select {
		width: 100%;
		max-width: 100%; }
	ul:not(.social) {
		@include reset-ul;
		text-align: left;
		li {
			display: block;
			margin-bottom: 20px;
			a {
				&:hover {
					color: var(--color-accent-primary); } }
			&:last-child {
				margin-bottom: 0; } }
		ul {
			width: 100%;
			padding-left: 1em;
			margin-top: 1em; } }
	ul {
		li {
			a {
				background-image: none; } } } }

.widgettitle {
	display: block;
	font-family: $font-secondary;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-bottom: 1.5em;
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		background-color: $dark;
		margin-bottom: 0.5em; } }

/* ======================================================================== */
/* 60. widgetArchive */
/* ======================================================================== */
.widget_archive {
	ul {
		li {
			display: flex !important;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			span {
				color: $dark; } } } }

/* ======================================================================== */
/* 61. widgetCalendar */
/* ======================================================================== */
#wp-calendar {
	width: 100%;
	text-align: center;
	caption {
		caption-side: top;
		width: 100%;
		text-align: center;
		padding-top: 0;
		padding-bottom: 10px; }
	th {
		font-weight: 600;
		padding: 5px;
		text-align: center; }
	td {
		padding: 5px;
		color: $dark;
		&#next {
			text-align: right; }
		&#prev {
			text-align: left; } }
	tr {
		border-bottom: none; } }

/* ======================================================================== */
/* 62. widgetCategories */
/* ======================================================================== */
.widget_categories {
	ul {
		li {
			display: flex !important;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			span {
				color: $brown-grey-2;
				font-size: 13px;
				line-height: 1; } } } }

/* ======================================================================== */
/* 63. widgetLogo */
/* ======================================================================== */
.widget_ruben_logo {
	max-width: 360px;
	.logo {
		margin-bottom: 2em; } }

@media screen and (max-width: $md) {
	.widget_ruben_logo {
		max-width: 100%;
		.logo {
			margin-bottom: 1em; } } }

/* ======================================================================== */
/* 64. widgetNavMenu */
/* ======================================================================== */
.widget_nav_menu {
	ul.menu {
		> li {
			margin-bottom: 1em !important;
			white-space: normal;
			word-break: break-word;
			a {
				display: inline-block;
				padding: 0;
				font-size: 16px;
				&:after, &:before {
					display: none; } } }
		> li.menu-item-has-children {
			margin-bottom: 1.5em !important;
			a {
				&:after {
					display: none; } } } }
	ul.sub-menu {
		background-color: transparent;
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		opacity: 1;
		visibility: visible;
		box-shadow: none;
		border-bottom: none;
		transform: none;
		margin-top: 1em;
		> li {
			a {
				padding: 3px 0; } } } }

/* ======================================================================== */
/* 65. widgetRSS */
/* ======================================================================== */
.widget_rss {
	.rsswidget {
		font-weight: 600; }
	.rss-date {
		display: block;
		width: 100%;
		font-size: 13px;
		margin-top: 0.5em;
		margin-bottom: 1em; }
	.rssSummary {
		margin-top: 1em;
		margin-bottom: 1em; }
	ul {
		> li {
			border-bottom: 1px solid $borders-dark;
			padding-bottom: 1em;
			&:last-child {
				border-bottom: none;
				padding-bottom: 0; } } } }

/* ======================================================================== */
/* 66. widgetRecentComments */
/* ======================================================================== */

/* ======================================================================== */
/* 67. widgetRecentEntries */
/* ======================================================================== */
.widget_recent_entries {
	.post-date {
		display: block;
		width: 100%;
		font-size: 13px;
		margin-top: 0.25em;
		color: $brown-grey-2; }
	ul {
		li {
			padding: 20px 0;
			margin-bottom: 0 !important;
			border-bottom: 1px solid $borders-dark;
			&:first-child {
				padding-top: 0; }
			&:last-child {
				padding-bottom: 0;
				border-bottom: none; } } } }
