/* ======================================================================== */
/* 5. figureImage */
/* ======================================================================== */
.figure-image
	text-align: center
	img
		width: 100%
	h5
		display: block
		width: 100%
		margin-top: 1em
