/* ======================================================================== */
/* 33. sectionCTA */
/* ======================================================================== */
.section-cta__wrapper-button
	margin-top: 35px
.section-cta__headline
	width: 1px
	height: 100px
	background-color: $dark
	margin-bottom: 55px
	margin-left: auto
	margin-right: auto

@media screen and (max-width: $md)
	.section-cta__wrapper-button
		margin-top: 25px
